import * as bootstrap from "bootstrap";

// TODO
// Close content box if the same tab is clicked

const app = (function () {
  // Initialize Temporary button for initial opening of content box co2
  // Only required for click on first range step because no change event is happening
  // Clicks on second and third year works without temporary button due to change of range slider state
  const initCo2InitialOpenButton = () => {
    let initialOpenButton = document.querySelector(
      ".c-emission-range-slider #years .temporary-button"
    );
    let initialTextInfo = document.querySelector(".c-map__initial-text");
    if (initialOpenButton) {
      initialOpenButton.addEventListener("click", (event) => {
        // Get slideshow and range slider dom elements
        let co2CarouselElement = document.querySelector("#co2Slider");
        let co2RangeSlider = document.querySelector("#co2RangeSlider");
        if (co2CarouselElement && co2RangeSlider) {
          // Create carousel instance
          let co2CarouselInstance = new bootstrap.Carousel(co2CarouselElement);
          let pagerElement = document.querySelector(
            ".tab-pane--co2 .carousel-pager__year"
          );
          if (co2CarouselInstance && pagerElement) {
            // Slide to carousel item 0 and set range slider value to 0
            co2CarouselInstance.to("0");
            co2RangeSlider.value = "0";
            pagerElement.innerHTML = "2027";
          }
          // show content box when range slider changes value
          let triggerEl = document.querySelector(
            '#tab--co2[data-bs-target="#tab-co2-pane"]'
          );
          if (triggerEl) {
            console.log("Tab trigger found");
            let tab = new bootstrap.Tab(triggerEl);
            tab.show();
          }
        }
        //delete temporary button to enable normal range actions
        event.currentTarget.style.display = "none";

        if (initialTextInfo) {
          initialTextInfo.style.display = "none";
        }
      });
    }
    return 1;
  };

  // Control co2 carousel via the co2 range slider element
  const initCo2Component = () => {
    // Get range slider, year pager, pager buttons carousel and content box
    let co2RangeSlider = document.querySelector("#co2RangeSlider");
    let pagerElement = document.querySelector(
      ".tab-pane--co2 .carousel-pager__year"
    );
    let co2CarouselElement = document.querySelector("#co2Slider");

    let co2ContentBoxElement = document.querySelector(".tab-pane--co2");
    let co2PagerButtons = document.querySelectorAll(
      ".tab-pane--co2 .c-content-box__pager button"
    );

    // Get chart dom elements
    let firstBarChart = document.querySelector(
      ".tab-pane--co2 .carousel-item__chart.first"
    );
    let secondBarChart = document.querySelector(
      ".tab-pane--co2 .carousel-item__chart.second"
    );
    let thirdBarChart = document.querySelector(
      ".tab-pane--co2 .carousel-item__chart.third"
    );

    let initialOpenButton = document.querySelector(
      ".c-emission-range-slider #years .temporary-button"
    );
    let initialTextInfo = document.querySelector(".c-map__initial-text");

    let currentCarouselItem = 0;
    let currentCarouselItemId = 0;

    if (co2RangeSlider) {
      // Add input change listener to range slider
      // slide to carousel item x on range slider change get carousel dom element

      co2RangeSlider.addEventListener("input", function (changeEvent) {
        if (initialTextInfo) {
          initialTextInfo.style.display = "none";
        }
        if (co2CarouselElement && co2CarouselElement) {
          let co2CarouselInstance = new bootstrap.Carousel(co2CarouselElement);
          switch (changeEvent.target.value) {
            case "0":
              console.log("Previous Year: " + pagerElement.innerHTML);
              console.log("New Year: " + changeEvent.target.value);

              currentCarouselItem = co2CarouselElement.querySelector(
                ".carousel-item.active"
              );
              currentCarouselItemId = currentCarouselItem.getAttribute("id");

              // Add/Remove chart animation classes depending on the previous year
              if (currentCarouselItemId === "co2CarouselItemThird") {
                firstBarChart.classList.remove("--2-to-1");
                firstBarChart.classList.add("--3-to-1");
              }
              if (currentCarouselItemId === "co2CarouselItemSecond") {
                firstBarChart.classList.remove("--3-to-1");
                firstBarChart.classList.add("--2-to-1");
              }
              // Slide to carousel page x
              co2CarouselInstance.to("0");
              // change year pager content to year x
              pagerElement.innerHTML = "2027";

              break;
            case "1":
              console.log("Previous Year: " + pagerElement.innerHTML);
              console.log("New Year: " + changeEvent.target.value);

              currentCarouselItem = co2CarouselElement.querySelector(
                ".carousel-item.active"
              );
              currentCarouselItemId = currentCarouselItem.getAttribute("id");

              // Add/Remove chart animation classes depending on the previous year
              if (currentCarouselItemId === "co2CarouselItemFirst") {
                secondBarChart.classList.remove("--3-to-2");
                secondBarChart.classList.add("--1-to-2");
              }
              if (currentCarouselItemId === "co2CarouselItemThird") {
                secondBarChart.classList.remove("--1-to-2");
                secondBarChart.classList.add("--3-to-2");
              }
              // Slide to carousel page x
              co2CarouselInstance.to("1");
              // change year pager content to year x
              pagerElement.innerHTML = "2031";

              break;
            case "2":
              console.log("Previous Year: " + pagerElement.innerHTML);
              console.log("New Year: " + changeEvent.target.value);

              // Add/Remove chart animation classes depending on the previous year
              currentCarouselItem = co2CarouselElement.querySelector(
                ".carousel-item.active"
              );
              currentCarouselItemId = currentCarouselItem.getAttribute("id");

              if (currentCarouselItemId === "co2CarouselItemSecond") {
                thirdBarChart.classList.remove("--1-to-3");
                thirdBarChart.classList.add("--2-to-3");
              }
              if (currentCarouselItemId === "co2CarouselItemFirst") {
                thirdBarChart.classList.remove("--2-to-3");
                thirdBarChart.classList.add("--1-to-3");
              }
              // Slide to carousel page x
              co2CarouselInstance.to("2");
              // change year pager content to year x
              pagerElement.innerHTML = "2040";

              break;
          }
        }
        if (initialOpenButton && initialOpenButton.style.display === "block") {
          initialOpenButton.style.display = "none";
        }
        // show carousel content box when range slider changes value
        let triggerEl = document.querySelector(
          '#tab--co2[data-bs-target="#tab-co2-pane"]'
        );
        if (triggerEl) {
          let tab = new bootstrap.Tab(triggerEl);
          tab.show();
        }
      });

      // Add click listeners on co2 pager buttons
      // Change pager state to year X depending on slide direction and previous active carousel item
      // Sync range slider state with pager button action
      if (co2CarouselElement) {
        co2PagerButtons.forEach((pagerButton) => {
          pagerButton.addEventListener("click", (event) => {
            let currentPagerButton = event.currentTarget;
            let slideDirection =
              currentPagerButton.getAttribute("data-bs-slide");

            /*  currentCarouselItem = co2CarouselElement.querySelector('.carousel-item.active');
              currentCarouselItemId = currentCarouselItem.getAttribute('id'); */
            let currentYear = pagerElement.innerHTML;
            console.log(currentCarouselItem);

            if (slideDirection === "next") {
              switch (currentYear) {
                case "2027":
                  pagerElement.innerHTML = 2031;
                  co2RangeSlider.value = "1";
                  secondBarChart.classList.remove("--3-to-2");
                  secondBarChart.classList.add("--1-to-2");
                  break;
                case "2031":
                  pagerElement.innerHTML = 2040;
                  co2RangeSlider.value = "2";
                  thirdBarChart.classList.remove("--1-to-3");
                  thirdBarChart.classList.add("--2-to-3");
                  break;
                case "2040":
                  pagerElement.innerHTML = 2027;
                  co2RangeSlider.value = "0";
                  firstBarChart.classList.remove("--2-to-1");
                  firstBarChart.classList.add("--3-to-1");
                  break;
              }
            }

            if (slideDirection === "prev") {
              switch (currentYear) {
                case "2027":
                  pagerElement.innerHTML = 2040;
                  co2RangeSlider.value = "2";
                  thirdBarChart.classList.remove("--2-to-3");
                  thirdBarChart.classList.add("--1-to-3");
                  break;
                case "2031":
                  pagerElement.innerHTML = 2027;
                  co2RangeSlider.value = "0";
                  firstBarChart.classList.remove("--3-to-1");
                  firstBarChart.classList.add("--2-to-1");
                  break;
                case "2040":
                  pagerElement.innerHTML = 2031;
                  co2RangeSlider.value = "1";
                  secondBarChart.classList.remove("--1-to-2");
                  secondBarChart.classList.add("--3-to-2");
                  break;
              }
            }
          });
        });
      }
    }
    return 1;
  };

  const initCo2PagerButton = () => {
    let slides = 0;
    let previousCarouselItem = 0;
    let previousCarouselItemId = 0;
    let co2CarouselElement = document.querySelector("#co2Slider");
    let co2ContentBoxElement = document.querySelector(".tab-pane--co2");
    slides = co2ContentBoxElement.querySelectorAll(".carousel-item");

    let currentPagerYear = co2ContentBoxElement.querySelector(
      ".carousel-pager__year"
    );
    let co2PagerButtons = co2ContentBoxElement.querySelectorAll(
      ".c-content-box__pager button"
    );
    co2PagerButtons.forEach((pagerButton) => {
      pagerButton.addEventListener("click", (event) => {
        let currentPagerButton = event.currentTarget;
        let currentYear = currentPagerYear.innerHTML;

        previousCarouselItem = co2CarouselElement.querySelector(
          ".carousel-item.active"
        );
        previousCarouselItemId = previousCarouselItem.getAttribute("id");
        if (currentPagerButton.getAttribute("data-bs-slide") === "next") {
          switch (currentYear) {
            case "#co2CarouselItemFirst":
              currentPagerYear.innerHTML = 2031;
              break;
            case "#co2CarouselItemSecond":
              currentPagerYear.innerHTML = 2040;
              break;
            case "#co2CarouselItemThird":
              currentPagerYear.innerHTML = 2027;
              break;
          }
        }
        if (currentPagerButton.getAttribute("data-bs-slide") === "prev") {
          switch (currentYear) {
            case "#co2CarouselItemFirst":
              currentPagerYear.innerHTML = 2040;
              break;
            case "#co2CarouselItemSecond":
              currentPagerYear.innerHTML = 2027;
              break;
            case "#co2CarouselItemThird":
              currentPagerYear.innerHTML = 2031;
              break;
          }
        }
      });
    });

    ///
  };

  const initLocationComponent = () => {
    let slides = 0;
    let closeButton = 0;
    let currentSlide = 1;
    // Set pager total to amount of slides
    let contentBoxes = document.querySelectorAll(".c-content-box__item");
    if (contentBoxes) {
      contentBoxes.forEach((contentBox) => {
        //console.log(contentBox);
        slides = contentBox.querySelectorAll(".carousel-item");
        closeButton = contentBox.querySelector(".c-content-box__close-button");
        if (slides) {
          // set pager total
          let pagerTotal = contentBox.querySelector(
            ".carousel-pager .carousel-pager__total"
          );
          if (pagerTotal) {
            pagerTotal.innerHTML = slides.length;
          }
        }
      });
    }

    // for each carousel content box button add click event
    let contentBoxButtons = document.querySelectorAll(
      ".tab-pane--locations .c-content-box__pager button"
    );
    contentBoxButtons.forEach((contentBoxButtonElement) => {
      contentBoxButtonElement.addEventListener("click", (event) => {
        let currentContentBoxButtonElement = event.currentTarget;
        let currentContentBox =
          currentContentBoxButtonElement.parentElement.parentElement;
        let currentPagerElement =
          currentContentBoxButtonElement.parentElement.querySelector(
            ".carousel-pager__current"
          );
        let carouselTargetId =
          currentContentBoxButtonElement.getAttribute("data-bs-target");
        /* console.log("Current Pager: ");
        console.log(currentPagerElement); */

        // Get all slide items
        slides = document.querySelectorAll(
          carouselTargetId + " .carousel-item"
        );
        let slideDirection =
          contentBoxButtonElement.getAttribute("data-bs-slide");
        console.log("Slides: " + slides.length);

        if (currentPagerElement) {
          if (slideDirection === "next") {
            if (currentSlide % slides.length === 0) {
              currentPagerElement.innerHTML = 1;
              currentSlide = 1;
              console.log("Next slide is " + currentSlide);
            } else {
              currentPagerElement.innerHTML = ++currentSlide;
              console.log("Next slide is " + currentSlide);
            }
          } else if (slideDirection === "prev") {
            if (currentSlide - 1 === 0) {
              currentPagerElement.innerHTML = slides.length;
              currentSlide = slides.length;
              console.log("Next slide is " + currentSlide);
            } else {
              currentPagerElement.innerHTML = --currentSlide;
              console.log("Next slide is " + currentSlide);
            }
          }
        }
      });
    });

    return 1;
  };

  const syncCarouselPageCounterCurrent = () => {
    let slides = 0;
    // for each carousel content box button add click event
    let contentBoxButtons = document.querySelectorAll(
      ".c-content-box__pager-button"
    );
    contentBoxButtons.forEach((contentBoxButton) => {
      contentBoxButton.addEventListener("click", (event) => {
        let currentContentBoxButtonElement = event.currentTarget;
        let currentContentBox =
          currentContentBoxButtonElement.parentElement.parentElement;
        let currentPagerElement =
          currentContentBoxButtonElement.parentElement.querySelector(
            ".carousel-pager__current"
          );
        let carouselTargetId =
          currentContentBoxButtonElement.getAttribute("data-bs-target");
        /* console.log("Current Pager: ");
        console.log(currentPagerElement); */

        // Get all slide items
        slides = document.querySelectorAll(
          carouselTargetId + " .carousel-item"
        );
        console.log("Slides: " + slides.length);

        if (currentPagerElement) {
          if (contentBoxButton.getAttribute("data-bs-slide") === "next") {
            if ((currentSlide + 1) % (slides.length + 1) === 0) {
              currentPagerElement.innerHTML = 1;

              currentSlide = (++currentSlide % (slides.length + 1)) + 1;
              console.log("Next slide is " + currentSlide);
            } else {
              currentPagerElement.innerHTML =
                ++currentSlide % (slides.length + 1);
              console.log(currentSlide);
            }
            currentSlide = currentSlide % (slides.length + 1);
          } else if (
            contentBoxButton.getAttribute("data-bs-slide") === "prev"
          ) {
            if (currentSlide - 1 === 0) {
              currentPagerElement.innerHTML = 3;
              currentSlide = 3;
              console.log("Next slide is " + currentSlide);
            } else {
              currentPagerElement.innerHTML =
                --currentSlide % (slides.length + 1);
              console.log(currentSlide);
            }
            currentSlide = currentSlide % (slides.length + 1);
          }
        }
      });
    });
    return 1;
  };

  const initCloseButton = () => {
    let slides = 0;
    let closeButton = 0;
    // Set pager total to amount of slides
    let contentBoxes = document.querySelectorAll(".c-content-box__item");
    if (contentBoxes) {
      contentBoxes.forEach((contentBox) => {
        closeButton = contentBox.querySelector(".c-content-box__close-button");
        if (closeButton) {
          closeButton.addEventListener("click", (event) => {
            let activeContentBox = document.querySelector(
              ".c-content-box__item.active"
            );
            let activeTab = document.querySelector(".c-location-button.active");
            let initialTextInfo = document.querySelector(
              ".c-map__initial-text"
            );
            if (activeContentBox && activeTab) {
              activeContentBox.classList.remove("active", "show");
              activeTab.classList.remove("active");
              activeTab.setAttribute("arria-selected", "false");
            }

            if (initialTextInfo) {
              initialTextInfo.style.display = "block";
            }

            if (activeContentBox.classList.contains("tab-pane--co2")) {
              let initialOpenButton = document.querySelector(
                ".c-emission-range-slider #years .temporary-button"
              );
              let firstBarChart = document.querySelector(
                ".tab-pane--co2 .carousel-item__chart.first"
              );
              let co2CarouselElement = document.querySelector("#co2Slider");
              let co2RangeSlider = document.querySelector("#co2RangeSlider");
              let initialTextInfo = document.querySelector(
                ".c-map__initial-text"
              );
              if (initialTextInfo) {
                initialTextInfo.style.display = "block";
              }
              if (initialOpenButton) {
                initialOpenButton.style.display = "block";
              }
              if (co2CarouselElement && co2RangeSlider && firstBarChart) {
                let co2CarouselInstance = new bootstrap.Carousel(
                  co2CarouselElement
                );
                if (co2CarouselInstance) {
                  // Slide to slide 0 and set rangeSlider value to 0
                  co2CarouselInstance.to("0");

                  co2RangeSlider.value = "0";
                  firstBarChart.classList.remove("--2-to-1");
                  firstBarChart.classList.remove("--3-to-1");
                }
              }
            }
          });
        }
      });
    }
  };
  // add click listener for location buttons and change page current counter
  const resetElementsWhenLocationButtonsClicked = () => {
    // get all location buttons
    let locationButtons = document.querySelectorAll(".c-location-button");
    let firstBarChart = document.querySelector(
      ".tab-pane--co2 .carousel-item__chart.first"
    );
    locationButtons.forEach((locationButton) => {
      // for each location button
      locationButton.addEventListener("click", (event) => {
        // reset range slider and co2 slideshow to 0
        let co2CarouselElement = document.querySelector("#co2Slider");
        let co2RangeSlider = document.getElementById("co2RangeSlider");
        let initialTextInfo = document.querySelector(".c-map__initial-text");
        if (initialTextInfo) {
          initialTextInfo.style.display = "none";
        }
        if (co2CarouselElement && co2RangeSlider) {
          let co2CarouselInstance = new bootstrap.Carousel(co2CarouselElement);
          if (co2CarouselInstance) {
            // Slide to slide 0 and set rangeSlider value to 0
            co2CarouselInstance.to("0");

            co2RangeSlider.value = "0";
            firstBarChart.classList.remove("--2-to-1");
            firstBarChart.classList.remove("--3-to-1");
          }
        }
        // reset static location names (gasübernahmestationen und speicher)
        let staticLocationNames = document.querySelectorAll(
          ".c-map__location--static .c-icon-wrapped__popup.active"
        );
        staticLocationNames.forEach((name) => {
          name.classList.remove("active");
        });

        let initialOpenButton = document.querySelector(
          ".c-emission-range-slider #years .temporary-button"
        );
        if (initialOpenButton) {
          initialOpenButton.style.display = "block";
        }
      });
    });
    return 1;
  };

  const initStaticLocations = () => {
    // static icons toggle function
    // get all static icons
    /* let staticIcons = document.querySelectorAll(".c-map__location--static");
    staticIcons.forEach((icon) => {
      icon.addEventListener("click", (event) => {
        if ( icon.classList.contains("active")) {
          let iconInfo = event.currentTarget.querySelector(
            ".c-icon-wrapped__popup.active"
          );
          if (iconInfo) {
            iconInfo.classList.toggle("active");
          }
        }
      });
    }); */
    return 1;
  };

  const setFullHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };
  // Function to set the navbar height as a CSS variable
  const adjustMarginTop = () => {
    const navBar = document.querySelector('.nav-bar');

    if (navBar) {
      const navHeight = navBar.offsetHeight;
      document.documentElement.style.setProperty('--navbar-height', `${navHeight}px`);
    }
  };


  const initTooltips = () => {
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
    const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
  }

  const initCo2Carousel = () => {
    let co2Carousel = document.querySelector("#co2Slider");
    if (co2Carousel) {
      let co2CarouselInstance = new bootstrap.Carousel(co2Carousel, {
        touch: false,
      });
    }
  };

  const initFrontend = () => {
    initCo2InitialOpenButton();
    initCo2Component();
    initCo2Carousel();
    initLocationComponent();
    syncCarouselPageCounterCurrent();
    resetElementsWhenLocationButtonsClicked();
    initStaticLocations();
    initCloseButton();
  };
  return {
    initFrontend,
    setFullHeight
    /* adjustMarginTop */
    /* initTooltips */
  };
})();

document.addEventListener("DOMContentLoaded", function (event) {

  app.initFrontend();
  app.setFullHeight();
  /* app.adjustMarginTop(); */
  /* app.initTooltips(); */
  /* var element = document.querySelector(".button--fullscreen");

  // make the element go to full-screen mode
    element.addEventListener("click", () => {
      document.body.requestFullscreen()
      .then(function() {
        // element has entered fullscreen mode successfully
        console.log("Fullscreen");
      })
      .catch(function(error) {
        // element could not enter fullscreen mode
        console.log("Fullscreen not possible");
      });
    }); */
});

// Update the height whenever the window is resized
window.addEventListener("resize", app.setFullHeight);
/*window.addEventListener("resize", app.adjustMarginTop); */
